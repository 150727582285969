import {
  addDays,
  addMonths, differenceInDays, eachDayOfInterval, format,
  formatISO, isAfter, isBefore, isMonday, isSameMonth, isSunday, lastDayOfMonth, nextSunday,
  parse, parseISO, previousMonday, subDays,
  subMonths
} from 'date-fns'
import {SearchParamsDateRange} from "@/src/core/app/domain/@types/SearchParamsDateRange";
import {FormInputSelectOption} from "@/src/ui/@types/form/FormInputSelectOption";
import { DateRange } from '@/src/core/app/domain/@types/DateRange';
// import { enGB, es, fr, de } from 'date-fns/locale'
//
// const locales = {enGB, es, fr, de};

export const DATE_ISO_FORMAT = 'yyyy-MM-dd';
export const DATE_DEFAULT_FORMAT = 'dd/MM/yy';
export const DATETIME_DEFAULT_FORMAT = 'dd/MM/yy HH:mm';


const CalendarUtils = {
  capitalizeString: (word: string): string => {
    return word
      .split("")
      .map((letter, index) => (index === 0 ? letter.toUpperCase() : letter))
      .join("")
  },
  dayToString: (day: Date) : string => {
    return formatISO(day, { representation: 'date' });
  },
  isValidDay: (day: string) : boolean => {
    const dt = CalendarUtils.parseDay(day);
    return !isNaN(dt.getTime());
  },
  parseDay: (day: string) : Date => {
    return parse(day, DATE_ISO_FORMAT, CalendarUtils._getToday());
  },
  parseDateTime: (dt: string) : Date => {
    return parseISO(dt);
  },
  _getToday: () : Date => {
    return new Date();
  },
  getToday: () : string => {
    return CalendarUtils.dayToString(CalendarUtils._getToday());
  },
  _getFirstDayInMonth: (date: Date): Date => {
    return new Date(date.getFullYear(), date.getMonth(), 1);
  },
  _call: (date: string, fn: (d: Date) => Date) : string => {
    let d = CalendarUtils.parseDay(date);
    d = fn(d);
    return CalendarUtils.dayToString(d);
  },
  getFirstDayInMonth: (date: string): string => {
    return CalendarUtils._call(date, (d) => CalendarUtils._getFirstDayInMonth(d));
  },
  isCurrentMonth: (date: string) : boolean => {
    const firstDayInMonth = CalendarUtils.getFirstDayInMonth(date);
    const currentFirstDayInMonth = CalendarUtils.getFirstDayInMonth(CalendarUtils.getToday());
    return firstDayInMonth === currentFirstDayInMonth;
  },
  _getNextMonth: (date: Date): Date => {
    let d = CalendarUtils._getFirstDayInMonth(date);
    return addMonths(d, 1);
  },
  getNextMonth: (date: string): string => {
    return CalendarUtils._call(date, (d) => CalendarUtils._getNextMonth(d));
  },
  _getPreviousMonth: (date: Date): Date => {
    let d = CalendarUtils._getFirstDayInMonth(date);
    return subMonths(d, 1);
  },
  getPreviousMonth: (date: string): string => {
    return CalendarUtils._call(date, (d) => CalendarUtils._getPreviousMonth(d));
  },
  addDays: (date: string, daysAmount: number): string => {
    return CalendarUtils._call(date, (d) => addDays(d, daysAmount));
  },
  addMonths: (date: string, amount: number): string => {
    return CalendarUtils._call(date, (d) => addMonths(d, amount));
  },
  subDays: (date: string, daysAmount: number): string => {
    return CalendarUtils._call(date, (d) => subDays(d, daysAmount));
  },
  subDaysOrToday: (date: string, daysAmount: number): string => {
    const today = CalendarUtils.getToday();
    const result = CalendarUtils._call(date, (d) => subDays(d, daysAmount));
    if (CalendarUtils.isBefore(result, today)) {
      return today;
    }
    return result;
  },
  getDisplayMonthDates: (date: string) : string[] => {
    let dFirstDayOfMonth = CalendarUtils.parseDay(date);
    let dLastDayOfMonth = lastDayOfMonth(dFirstDayOfMonth);

    let start = isMonday(dFirstDayOfMonth) ? dFirstDayOfMonth : previousMonday(dFirstDayOfMonth);
    let end = isSunday(dLastDayOfMonth) ? dLastDayOfMonth : nextSunday(dLastDayOfMonth);

    return eachDayOfInterval({
      start,
      end,
    }).map(d => CalendarUtils.dayToString(d));
  },
  _formatDate: (date: Date, formatToApply : string = DATE_DEFAULT_FORMAT): string => {
    return format(date, formatToApply);
    // @ts-ignore
    // return format(date, 'P', {locale: locales[window.__localeId__]});
  },
  formatDate: (date: string, formatToApply : string = DATE_DEFAULT_FORMAT) : string => {
    return CalendarUtils._formatDate(CalendarUtils.parseDay(date), formatToApply);
  },
  formatDateTime: (dt: string, formatToApply : string = DATETIME_DEFAULT_FORMAT) : string => {
    return CalendarUtils._formatDate(CalendarUtils.parseDateTime(dt), formatToApply);
  },
  formatMonthAndYear: (date: string, langcode: string): string => {
    const d = CalendarUtils.parseDay(date);
    const intlCode = CalendarUtils.getIntlCodeForLangcode(langcode);
    const month = new Intl.DateTimeFormat(intlCode, { month: "long" }).format(d);
    const year = d.getFullYear();
    return CalendarUtils.capitalizeString(month) + " " + year;
  },
  formatDayAndMonth: (date: string, langcode: string): string => {
    const d = CalendarUtils.parseDay(date);
    const intlCode = CalendarUtils.getIntlCodeForLangcode(langcode);
    const month = new Intl.DateTimeFormat(intlCode, { month: "long" })
      .format(d)
      .slice(0, 3);
    return d.getDate() + " " + month;
  },

  isBefore: (date: string, dateToCompare: string) : boolean => {
    const d1 = CalendarUtils.parseDay(date);
    const d2 = CalendarUtils.parseDay(dateToCompare);
    return isBefore(d1, d2);
  },

  isAfter: (date: string, dateToCompare: string) : boolean => {
    const d1 = CalendarUtils.parseDay(date);
    const d2 = CalendarUtils.parseDay(dateToCompare);
    return isAfter(d1, d2);
  },

  isBeforeToday: (date: string) : boolean => {
    const dateToCompare = CalendarUtils.getToday();
    const d1 = CalendarUtils.parseDay(date);
    const d2 = CalendarUtils.parseDay(dateToCompare);
    return isBefore(d1, d2);
  },

  isDateInRange: (date: string, range?: SearchParamsDateRange) : boolean => {
    if (!range || !range.start || !range.end) {
      return false;
    }
    const d = CalendarUtils.parseDay(date);
    const start = CalendarUtils.parseDay(range.start);
    const sOneDayBeforeEnd = CalendarUtils.subDays(range.end, 1);
    const oneDayBeforeEnd = CalendarUtils.parseDay(sOneDayBeforeEnd);

    return !isBefore(d, start) && !isAfter(d, oneDayBeforeEnd);
  },
  isDateInMonth: (date: string, month: string) : boolean => {
    const d = CalendarUtils.parseDay(date);
    const m = CalendarUtils.parseDay(month);
    return isSameMonth(d, m);
  },
  getDayOptions: () : FormInputSelectOption[] => {
    return Array(31).fill(1).map((item, index) => ({
      value: (index + 1).toString(),
      label: `${index + 1}`,
    }));
  },
  getMonthOptions: () : FormInputSelectOption[] => {
    return Array(12).fill(1).map((item, index) => ({
      value: (index + 1).toString(),
      label: `${index + 1}`,
    }));
  },
  getYearOptions: () : FormInputSelectOption[] => {
    const today = CalendarUtils._getToday();
    return Array(6).fill(1).map((item, index) => ({
      value: (today.getFullYear() + index).toString(),
      label: (today.getFullYear() + index).toString(),
    }));
  },
  getCurrentYear: () => {
    const today = CalendarUtils._getToday();
    return today.getFullYear();
  },
  getDiffDays: (from: string, to: string) : number => {
    const start = CalendarUtils.parseDay(from);
    const end = CalendarUtils.parseDay(to);
    return differenceInDays(end, start);
  },
  getDateRangeIterator: (dateRange: DateRange, skipEndDate: boolean = false) : string[] => {
    const diff = CalendarUtils.getDiffDays(dateRange.start, dateRange.end);
    const dates = [dateRange.start];
    for (let i = 0;  i < diff; i++) {
      dates.push(CalendarUtils.addDays(dateRange.start, i + 1));
    }

    if (skipEndDate) {
      dates.pop();
    }

    return dates;
  },

  getIntlCodeForLangcode: (langcode: string) : string => {
    const map = {
      'en': 'en-US',
      'es': 'es-ES',
      'de': 'de-DE',
      'fr': 'fr-FR',
    };
    // @ts-ignore
    return map[langcode];
  },

  formatCalendarHeaderDay: (d: string, langcode: string) : string => {
    const dd = CalendarUtils.parseDay(d);
    const intlCode = CalendarUtils.getIntlCodeForLangcode(langcode);
    const day = new Intl.DateTimeFormat(intlCode, { weekday: "short" }).format(dd);
    return `${day} ${CalendarUtils.formatDate(d, 'd/L')}`;
  },
  isRangeInRange: (small: DateRange, big: DateRange) => {
    return CalendarUtils.isDateInRange(small.start, big) && CalendarUtils.isDateInRange(small.end, big);
  },
  addDaysWithLimit(d: string, days: number, limitDay: string) {
    const newDay = CalendarUtils.addDays(d, days);
    if (CalendarUtils.isAfter(newDay, limitDay)) {
      return limitDay;
    }
    return newDay;
  }
};

export default CalendarUtils;
