import {useTranslation} from "next-i18next";
import CalendarUtils from "@/src/ui/utils/calendar";

const CopyrightMessage = () => {
  const now = CalendarUtils.getToday();
  const { t } = useTranslation();
  return (
    <>
      {t('global.copyright.message', {year: CalendarUtils.formatDate(now, 'yyyy')})}
    </>
  );
};

export default CopyrightMessage;
